import React, { useCallback, useState, useMemo, useEffect } from 'react';
import DynamicContainer from 'react-components/src/ui/layout/container/animated-dynamic-height.containter';
import LoadingSpinnerIcon from 'react-components/src/components/loading-spinner-icon';
// @ts-expect-error Javascript
import ReactNotification from 'react-notifications-component';
import Text from 'react-components/src/components/text';

import { motion, AnimatePresence } from 'framer-motion';
import { Message } from 'react-components';

// @ts-expect-error Javascript
import PasswordForm from './components/PasswordForm';
// @ts-expect-error Javascript
import VerificationForm from './components/VerificationForm';
import StatusWidget from './widgets/status.widget';

import { ReactComponent as Logo } from './assets/logo.svg';
import AppIcon from './widgets/app-icon.widget';
import EmailStep, { type GroupedConnections } from './components/email.step';

import {
  loginWithPasswordMutation,
  sendVerificationCodeMutation,
  loginWithCodeMutation,
  loginWithIdp,
  // @ts-expect-error javascript module
} from './mutations/auth/cognito';

const ERRORS = {
  sendVerificationCode:
    'An error occurred when trying to send the verification code, please verify your email and try again.',
  getAuthConnectionsByEmail:
    'An error occurred when fetching the login providers, please verify your email and try again.',
  loginWithPassword: 'Your credentials are incorrect. Please verify them and try again.',
  accountDoesNotExist: 'An account with your email was not found. Please verify your email and try again.',
};

const App = () => {
  const [step, setStep] = useState<'loading' | 'email' | 'email-code' | 'password'>('email');
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [session, setSession] = useState({});
  const [errorMessage, setError] = useState<React.ReactNode | null>(null);
  const [userConnections, setUserConnections] = useState<GroupedConnections | null>(null);

  const sendVerificationCode = useCallback(async (email: string) => {
    try {
      setError(null);
      setSession(await sendVerificationCodeMutation(email));
      setStep('email-code');
    } catch {
      setError(ERRORS.sendVerificationCode);
    }
  }, []);

  const loginWithCode = useCallback(
    async (_email: string, code: string) => {
      try {
        setError(null);
        await loginWithCodeMutation(session, code);
      } catch (e) {
        setError('Oops! It looks like you entered the wrong code, please try again');
        throw e;
      }
    },
    [session]
  );

  const loginWithPassword = useCallback(async (email: string, password: string) => {
    try {
      setError(null);
      await loginWithPasswordMutation(email, password);
    } catch {
      setError(ERRORS.loginWithPassword);
    }
  }, []);

  const onContinue = useCallback(
    async ({
      email,
      connection,
      connections,
    }: {
      email: string;
      connection: string;
      connections: GroupedConnections;
    }) => {
      setStep('loading');
      setUserEmail(email);
      setUserConnections(connections);

      if (['Username-Password-Connection', 'Username-Password-Authentication'].includes(connection)) {
        setStep('password');
        return;
      }
      if (!connection || connection === 'email') {
        sendVerificationCode(email);
      } else {
        loginWithIdp(connection);
      }
    },
    [sendVerificationCode]
  );

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const errorParam = params.get('error');
    if (errorParam) {
      setError(errorParam);
    }
  }, []);

  const onBack = useCallback(() => {
    setError(null);
    setSession({});
    setStep('email');
  }, []);

  const errorContainer = useMemo(() => {
    if (!errorMessage) {
      return null;
    }

    return <Message type={Message.MessageTypes.ERROR}>{errorMessage}</Message>;
  }, [errorMessage]);

  const searchParams = new URLSearchParams(window.location.search);
  const client = searchParams.get('client');

  const squiggleColor = useMemo(() => {
    if (!client) return '#ed0e61';
    if (client.includes('employee') || client.includes('mobile')) return '#ed0e61';
    if (client.includes('admin')) return '#7D79B3';
    if (client.includes('ops')) return '#70BDFF';
    if (client.includes('claims')) return '#73BF99';

    return '#ed0e61';
  }, [client]);

  const idp = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get('idp');
  }, []);

  useEffect(() => {
    if (idp !== null) {
      loginWithIdp(idp);
    }
  }, [idp]);

  if (idp !== null) {
    return null;
  }

  return (
    <main className="min-h-screen flex flex-col" id="sso-container">
      <svg
        id="brand-line"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1300 766"
        preserveAspectRatio="xMidYMid slice"
        overflow="visible"
        className="absolute top-0 left-0 w-full h-full z-0"
      >
        <path
          fill="none"
          stroke={squiggleColor}
          strokeLinejoin="round"
          strokeWidth="60"
          d="M0 127.3c122.4 0 221.6 99.1 221.6 221.4v146c0 121.4 98.5 219.7 220 219.7s219.8-98.3 219.8-219.6v-53.3c0-122.4 99.1-221.6 221.4-221.6h146a219.8 219.8 0 0 0 219.7-220"
        />
      </svg>

      <div className="flex-1 flex flex-col p-1 md:p-10 max-w-screen-sm mx-auto w-full">
        <div className="flex flex-col gap-8 md:gap-14 w-full items-center h-full flex-1">
          <Logo />

          <AnimatePresence mode="popLayout">
            <div className="flex flex-col gap-3 w-full">
              <motion.div
                initial={{ y: -25, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                className="flex flex-col w-full relative"
              >
                <AppIcon />

                <DynamicContainer>
                  <div className="flex flex-col gap-8 rounded-md bg-grayscale-0 w-full pt-6 pb-12">
                    {step === 'loading' && (
                      <div className="flex justify-center items-center h-full min-h-80">
                        <LoadingSpinnerIcon />
                      </div>
                    )}

                    {step === 'email' && (
                      <EmailStep
                        initialValue={{ email: userEmail || '', connections: userConnections ?? undefined }}
                        onContinue={onContinue}
                        errorMessage={errorMessage}
                      />
                    )}

                    {step === 'email-code' && (
                      <VerificationForm
                        email={userEmail}
                        loginWithCode={loginWithCode}
                        onResend={sendVerificationCode}
                        onBack={onBack}
                        errorMessage={errorContainer}
                      />
                    )}

                    {step === 'password' && (
                      <PasswordForm
                        email={userEmail}
                        loginWithPassword={loginWithPassword}
                        onBack={onBack}
                        errorMessage={errorContainer}
                      />
                    )}
                  </div>
                </DynamicContainer>
              </motion.div>
            </div>
          </AnimatePresence>
        </div>

        <div className="flex justify-center md:mt-auto py-4 md:py-0 z-10">
          <Text type="body" size="sm" className="text-grayscale-0">
            By signing in, you agree to our{' '}
            <a
              className="text-pink-100 focus:text-pink-200 hover:text-pink-200 underline"
              href="https://www.getbenepass.com/terms-of-service"
              target="_blank"
              rel="noopener noreferrer"
            >
              terms of service
            </a>{' '}
            and{' '}
            <a
              className="text-pink-100 focus:text-pink-200 hover:text-pink-200 underline"
              href="https://www.getbenepass.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              privacy policy
            </a>
            .
          </Text>
        </div>
      </div>
    </main>
  );
};

const Wrapper = () => (
  <>
    <StatusWidget />
    <ReactNotification />
    <App />
  </>
);

export default Wrapper;
