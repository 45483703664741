import React, { useCallback, useRef, useImperativeHandle } from 'react';
import { classNames } from 'utils/src/classnames';
import { XCircle as ClearIcon } from '@benepass/icons';
import twMerge from '../../utils/twMerge';
import { useTextClassNames } from '../text/styles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createSyntheticEvent = (event: Event, target: HTMLInputElement): any => ({
  nativeEvent: event,
  currentTarget: target,
  target,
  bubbles: event.bubbles,
  cancelable: event.cancelable,
  defaultPrevented: event.defaultPrevented,
  eventPhase: event.eventPhase,
  isTrusted: event.isTrusted,
  preventDefault: () => event.preventDefault(),
  isDefaultPrevented: () => event.defaultPrevented,
  stopPropagation: () => event.stopPropagation(),
  isPropagationStopped: () => event.defaultPrevented,
  persist: () => {
    // do nothing
  },
  timeStamp: event.timeStamp,
  type: event.type,
});

export type Props = {
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  allowClear?: boolean;
  className?: string;
  hasError?: boolean;
  disabled?: boolean;
  dataTestId?: string;
  containerClassName?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

const TextField = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      leftIcon,
      rightIcon,
      allowClear = false,
      className,
      disabled = false,
      hasError = false,
      dataTestId,
      onChange,
      containerClassName,
      ...props
    },
    ref
  ): JSX.Element => {
    const inputRef = useRef<HTMLInputElement>(null);

    useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(ref, () => inputRef.current, []);

    const text = useTextClassNames({
      type: 'body',
      size: 'lg',
    });

    const handleClear = useCallback(() => {
      if (!inputRef.current) {
        return;
      }

      inputRef.current.value = '';
      inputRef.current.focus();

      if (!onChange) {
        return;
      }

      // generate a change event, synthesize it, and call onChange with that event
      const event = new Event('change', {
        bubbles: true,
      });
      inputRef.current?.dispatchEvent(event);
      const syntheticEvent = createSyntheticEvent(event, inputRef.current);
      onChange(syntheticEvent);
    }, [onChange]);

    const border = classNames([
      hasError ? 'border-red-100' : 'border-grayscale-border',
      hasError ? 'focus:ring-red-100' : 'focus:ring-blue-100',
    ]);

    const background = classNames([
      {
        'bg-red-10': hasError ?? false,
        'bg-grayscale-2': disabled ?? false,
      },
    ]);

    const padding = classNames([leftIcon ? 'pl-12' : 'pl-4', rightIcon || allowClear ? 'pr-12' : 'pr-4']);

    const iconBaseClasses = 'absolute flex items-center';
    const iconClasses = {
      left: `${iconBaseClasses} left-4`,
      right: `${iconBaseClasses} right-4`,
    };

    return (
      <div className={twMerge('relative flex items-center w-full', containerClassName)}>
        <input
          {...props}
          className={twMerge(
            'w-full outline-none focus:ring-1 placeholder-grayscale-48 text-grayscale-80 disabled:text-grayscale-64',
            'border rounded-lg py-3',
            padding,
            background,
            text,
            border,
            '[&:disabled]:opacity-100',
            '[&:disabled]:[--webkit-text-fill-color:var(--grayscale-64)]',
            '[&:disabled].force-color:[--webkit-text-fill-color:var(--grayscale-80)]',
            '[&:disabled].force-color:text-grayscale-80',
            '[&:placeholder-shown~.icon_container_svg]:text-grayscale-48',
            className ?? ''
          )}
          disabled={disabled}
          data-testid={dataTestId}
          onChange={onChange}
          ref={inputRef}
        />
        {leftIcon && <div className={iconClasses.left}>{leftIcon}</div>}
        {rightIcon && <div className={iconClasses.right}>{rightIcon}</div>}
        {allowClear && !!inputRef.current?.value && (
          <button
            type="button"
            className={`${iconClasses.right} cursor-pointer`}
            onClick={handleClear}
            aria-label="Clear input"
          >
            <ClearIcon />
          </button>
        )}
      </div>
    );
  }
);

TextField.displayName = 'TextField';

export { default as useInput } from './hooks/use-input';

export default TextField;
