import Input, { type OtpInputProps } from 'react-otp-input';

const containerClassName = 'w-full grid grid-cols-6 md:h-16 h-12 gap-2 md:gap-4';
const inputClassName = `text-input-color text-center font-semibold bg-input-bg-color
        text-2xl md:text-4xl rounded-xl md:rounded-2xl
        w-full h-full`;

const OTPInput = ({ value, onChange }: { value: OtpInputProps['value']; onChange: OtpInputProps['onChange'] }) => (
  <Input
    value={value}
    onChange={onChange}
    numInputs={6}
    separator={false}
    isInputNum
    shouldAutoFocus
    focusStyle="focus:outline-none focus:ring focus:ring-indigo-100"
    containerStyle={containerClassName}
    inputStyle={inputClassName}
  />
);

export default OTPInput;
